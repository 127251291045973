<template>
  <div class="box">
    <div class="top">
      <img :src="top" alt="" />
    </div>
    <div class="aboutUsBox">
      <div class="aboutUs">
        <p class="aboutUs-p">关于我们</p>
        <p>ABOUT US</p>
        <div class="rectangle">
          <img :src="rectangle" alt="" />
        </div>
        <div class="aboutUs-middle">
          <div class="aboutUs-middle-left">
            <div class="aboutUs-middle-left-text">
              &emsp;&emsp;
              才智英才教育科技（武汉）有限公司是一家专业提供创新记忆法培训服务与教育软件开发的高新技术企业。

              <br />
              &emsp;&emsp;
              公司立足多年记忆法培训与教育咨询服务经验，拥有资深软件开发团队，不断提升公司的信息化建设水平，实现高科技、高品质、高成长的跃进式发展。
            </div>
            <div class="aboutUs-middle-left-bottom">
              <div>
                <img :src="highTech" alt="" />
              </div>
              <div>
                <img :src="highQuality" alt="" />
              </div>
              <div>
                <img :src="highGrowth" alt="" />
              </div>
            </div>
          </div>
          <div class="aboutUs-middle-right">
            <img :src="certificate" alt="" />
          </div>
        </div>
        <div class="aboutUs-bottom">
          <img :src="putOnRecords" alt="" />
        </div>
      </div>
    </div>
    <div class="pad">
      <p class="pad-p">科学训练工具——忆学小智记忆学习pad</p>
      <p>Yixuexiaozhi memory learning pad</p>
      <div class="rectangle">
        <img :src="rectangle" alt="" />
      </div>
      <div class="pad-text">
        &emsp;&emsp;忆学小智是由国家高新技术企业才智英才教育科技（武汉）有限公司研发打造，由专业资深的教研团队与技术开发团队，融合创新记忆方法与大数据、人工智能AI技术、互动教学等先进技术，将教育成果转化为颠覆性和前瞻性的教育产品，让学员轻松记忆、快乐学习的高效记忆学习机。
      </div>
      <div class="tablet">
        <img :src="tablet" alt="" />
      </div>
      <div class="pad-merit">
        <div>
          <img :src="brand" alt="" />
        </div>
        <div>
          <img :src="antiBlueRay" alt="" />
        </div>
        <div>
          <img :src="HdScreen" alt="" />
        </div>
        <div>
          <img :src="stableOperation" alt="" />
        </div>
      </div>
    </div>
    <div class="function">
      <p class="function-p">功能模块</p>
      <p>Function Module</p>
      <div class="rectangle">
        <img :src="rectangle" alt="" />
      </div>
      <div class="stimulate">
        <div class="title">
          <span>
            <img :src="ellipse" alt="" />
          </span>
          4大寓教于乐的激励机制
        </div>
        <div class="module">
          <div class="imageBox">
            <div class="pk">
              <img :src="pk" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="growth">
              <img :src="growth" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="store">
              <img :src="store" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="desk">
              <img :src="desk" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="memory-module">
        <div class="title">
          <span>
            <img :src="ellipse" alt="" />
          </span>
          5大“记忆+”学习模块
        </div>
        <div class="module">
          <div class="imageBox">
            <div class="Chinese">
              <img :src="Chinese" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="English">
              <img :src="English" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="synthesize">
              <img :src="synthesize" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="memory">
              <img :src="memory" alt="" />
            </div>
          </div>
          <div class="imageBox">
            <div class="memoryClass">
              <img :src="memoryClass" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="function-bottom">
        <div class="resource">
          <div class="title">
            <span>
              <img :src="ellipse" alt="" />
            </span>
            1个体量丰富的资源宝库
          </div>
          <div class="module">
            <div class="imageBox">
              <div class="studyTheBible">
                <img :src="studyTheBible" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="intelligenceTest">
          <div class="title">
            <span>
              <img :src="ellipse" alt="" />
            </span>
            1系列精准个性的智能测评
          </div>
          <div class="module">
            <div class="imageBox">
              <div class="smart">
                <img :src="smart" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <div class="more-image">
            <img :src="model" alt="" />
          </div>
          <div class="more-text">更多精彩内容 持续开发更新中 ……</div>
        </div>
      </div>
    </div>
    <div class="beian">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        >鄂ICP备2020021909号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      top: "https://cdn.yixuewang.cn/xiaozhi/picture/top.png",
      rectangle: "https://cdn.yixuewang.cn/xiaozhi/picture/rectangle.png",
      highTech: "https://cdn.yixuewang.cn/xiaozhi/picture/high-tech.png",
      highQuality: "https://cdn.yixuewang.cn/xiaozhi/picture/highQuality.png",
      highGrowth: "https://cdn.yixuewang.cn/xiaozhi/picture/highGrowth.png",
      certificate: "https://cdn.yixuewang.cn/xiaozhi/picture/certificate.webp",
      putOnRecords:
        "https://cdn.yixuewang.cn/xiaozhi/picture/putOnRecords.webp",
      tablet: "https://cdn.yixuewang.cn/xiaozhi/picture/tablet.webp",
      brand: "https://cdn.yixuewang.cn/xiaozhi/picture/brand.png",
      antiBlueRay: "https://cdn.yixuewang.cn/xiaozhi/picture/antiBlue-ray.png",
      HdScreen: "https://cdn.yixuewang.cn/xiaozhi/picture/HdScreen.png",
      stableOperation:
        "https://cdn.yixuewang.cn/xiaozhi/picture/stableOperation.png",
      ellipse: "https://cdn.yixuewang.cn/xiaozhi/picture/ellipse.png",
      pk: "https://cdn.yixuewang.cn/xiaozhi/picture/pk.png",
      growth: "https://cdn.yixuewang.cn/xiaozhi/picture/growth.png",
      store: "https://cdn.yixuewang.cn/xiaozhi/picture/store.png",
      desk: "https://cdn.yixuewang.cn/xiaozhi/picture/desk.png",
      English: "https://cdn.yixuewang.cn/xiaozhi/picture/English.png",
      synthesize: "https://cdn.yixuewang.cn/xiaozhi/picture/synthesize.png",
      memory: "https://cdn.yixuewang.cn/xiaozhi/picture/memory.png",
      memoryClass: "https://cdn.yixuewang.cn/xiaozhi/picture/memoryClass.png",
      Chinese: "https://cdn.yixuewang.cn/xiaozhi/picture/Chinese.webp",
      model: "https://cdn.yixuewang.cn/xiaozhi/picture/model.webp",
      studyTheBible:
        "https://cdn.yixuewang.cn/xiaozhi/picture/studyTheBible.png",
      smart: "https://cdn.yixuewang.cn/xiaozhi/picture/smart.png",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0;
}
.box {
  width: 100%;
  position: relative;
  /* height: 1000vh; */
}
.top {
  width: 100%;
}
.aboutUsBox {
  width: 100%;
  background-color: #eff8ff;
  padding: 100px 0 87px;
}
.aboutUs {
  width: 1020px;
  margin: 0 auto;
  text-align: center;
}
.aboutUs p,
.function p,
.pad p {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #abc3f8;
  line-height: 40px;
  margin-bottom: 18px;
}
.function .function-p,
.aboutUs .aboutUs-p,
.pad .pad-p {
  font-size: 38px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #233b6f;
  line-height: 40px;
}
.aboutUs .rectangle,
.function .rectangle {
  width: 73px;
  margin: 0 auto 53px;
}
.aboutUs-middle-left {
  display: inline-block;
  width: 536px;
  padding-top: 10px;
}
.aboutUs-middle-left-text {
  width: 100%;
  text-align: left;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #233b6f;
  line-height: 42px;
}
.aboutUs-middle-left-bottom {
  margin-top: 36px;
  width: calc(100% - 11px);
  height: 37px;
  display: flex;
  justify-content: space-between;
}
.aboutUs-middle-left-bottom div {
  height: 100%;
  width: 120px;
}
.aboutUs-middle-right {
  display: inline-block;
  margin-left: 48px;
  width: 422px;
}
.aboutUs-bottom {
  margin-top: 70px;
  width: 100%;
}
.pad {
  margin: 0 auto;
  width: 1000px;
  padding: 100px 0 90px;
}
.pad .rectangle {
  display: inline-block;
  width: 73px;
  height: 3px;
  background: #87abfa;
  margin-bottom: 63px;
}
.pad-text {
  text-align: left;
  width: 100%;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #233b6f;
  line-height: 42px;
  margin-bottom: 90px;
}
.pad-merit {
  margin: 110px 0 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pad-merit div {
  width: 220px;
  height: 261px;
}
.function {
  overflow: hidden;
  width: 100%;
  height: 1638px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("https://cdn.yixuewang.cn/xiaozhi/picture/bottom.webp");
}
.function p {
  color: #ffffff;
  opacity: 0.6;
}
.function .function-p {
  opacity: 1;
  color: #ffffff;
  margin-top: 100px;
}
.function .function-bottom,
.function .stimulate,
.function .memory-module {
  width: 990px;
  margin: 0 auto 65px;
}
.function .module {
  display: flex;
  justify-content: space-between;
}
.function .title {
  text-align: left;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
}
.function .title span {
  display: inline-block;
  width: 23px;
  height: 18px;
}
.function .function-bottom .module .imageBox div,
.function .stimulate .module .imageBox div,
.function .memory-module .module .imageBox div {
  margin: 0 auto;
  position: relative;
}
/* 四激励 */
.function .stimulate .module .imageBox {
  margin-top: 105px;
  width: 225px;
  height: 230px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.6);
}
.function .stimulate .module .imageBox .pk {
  width: 142px;
  height: 250px;
  top: -71px;
}
.function .stimulate .module .imageBox .growth {
  width: 153px;
  height: 242px;
  top: -63px;
}
.function .stimulate .module .imageBox .store {
  width: 153px;
  height: 244px;
  top: -65px;
}
.function .stimulate .module .imageBox .desk {
  width: 176px;
  height: 242px;
  top: -62px;
}
/* 五记忆模块 */
.function .memory-module .module .imageBox {
  margin-top: 130px;
  width: 187px;
  height: 230px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.6);
}
.function .memory-module .module .imageBox .Chinese {
  width: 156px;
  height: 289px;
  top: -88px;
}
.function .memory-module .module .imageBox .English {
  width: 156px;
  height: 294px;
  top: -93px;
}
.function .memory-module .module .imageBox .synthesize {
  width: 153px;
  height: 290px;
  top: -89px;
}
.function .memory-module .module .imageBox .memory {
  width: 156px;
  height: 290px;
  top: -90px;
}
.function .memory-module .module .imageBox .memoryClass {
  width: 156px;
  height: 290px;
  top: -90px;
}
/* 底部模块 */
.function .function-bottom {
  display: flex;
  justify-content: space-between;
}
.function .function-bottom .module .imageBox {
  margin-top: 126px;
  margin-left: 16px;
  width: 207px;
  height: 230px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.6);
}
.function .function-bottom .module .imageBox .studyTheBible {
  width: 153px;
  height: 280px;
  top: -70px;
}
.function .function-bottom .module .imageBox .smart {
  width: 159px;
  height: 280px;
  top: -70px;
}
.more {
  padding-top: 54px;
}
.more-image {
  width: 221px;
  height: 207px;
  margin-bottom: 21px;
}
.more-text {
  text-align: left;
  width: 132px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}

img {
  width: 100%;
}
.beian {
  width: 100%;
  position: absolute;
  bottom: 20px;
}
.beian a {
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
}
.beian a:active,
.beian a:hover {
  color: red;
  text-decoration: underline;
}
</style>
